import NexaLogo from '@/assets/nexa-logo.png';

const Logo = () => {
  return (
    <div className="flex gap-2 items-center">
      <img src={NexaLogo} alt="Nexa Studio" className="h-9 w-9" />
      <p className="text-nexa text-lg">Nexa</p>
    </div>
  );
};

export default Logo;
